// A hook for taking a list of userIds and fetching them, returning a map of userId to user object.
// In the future, we should be normalizing this data in the store so we can share it with other
// queries and only fetch users that are missing, as well as update the whole app when a user
// changes using tag invalidation.

import {skipToken} from "@reduxjs/toolkit/query/react";
import {GetUsersByIdRes, useGetUsersQuery} from "@store";
import {useMemo} from "react";

import {devError} from "../store/errors";

export type PopulateUserLookup = {[key: string]: GetUsersByIdRes};
export type PopulateUserLookupRes = {
  userLookup: PopulateUserLookup;
  isSuccess: boolean;
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
};

export function useGetPopulateUserLookup(userIds: string[]): PopulateUserLookupRes {
  if (userIds.length >= 150) {
    devError(`useGetPopulateUserLookup called with ${userIds.length} userIds`);
  }
  // Ensure we're only fetching actual user ids.
  const ids = userIds.filter((id) => id);
  // The downside of doing this is every spot that fetches users will have to do this query.
  // We don't normalize the users store in such a way we could share this with other queries.
  const populateUserQuery = useGetUsersQuery(
    Boolean(ids?.length) ? {_id: {$in: ids}, limit: 200} : skipToken
  );

  // Put this all in a useMemo or every hook that uses this will trigger a re-render.
  return useMemo(() => {
    // Make a lookup table
    const populateUserLookup = populateUserQuery.data?.data?.reduce((acc, user) => {
      acc[user._id] = user;
      return acc;
    }, {} as PopulateUserLookup);

    return {
      userLookup: populateUserLookup ?? {},
      isSuccess: populateUserQuery.isSuccess,
      error: populateUserQuery.error,
      isError: populateUserQuery.isError,
      isLoading: populateUserQuery.isLoading,
      isFetching: populateUserQuery.isFetching,
    };
  }, [
    populateUserQuery.data?.data,
    populateUserQuery.error,
    populateUserQuery.isError,
    populateUserQuery.isFetching,
    populateUserQuery.isLoading,
    populateUserQuery.isSuccess,
  ]);
}
